"use strict"

//Hide Preloader
function handlePreloader() {

	// if ($('.j-preloader').length) {
	// $('.j-preloader').delay(200).fadeOut(500);
	if ($('#typed').length) {
		initOverlayAnimate()
	}
	;

	// }
};
//Greeting message
function initOverlayAnimate() {
	new TypeIt('#typed', {
		speed: 60,
		breakLines: true,
		afterComplete: function () {
			$('.ti-cursor').fadeOut();
			$(".j-cover_btn").addClass('in');
		}
	}).pause(500)
		.type("<span class='j-typing small black'> Do your ads make enough sales?</span>")

		.break().pause(500)
		.type("<span class='j-typing x-small'> make direct sales</span>")
		.break().pause(500)
		.type("<span class='medium'>Straight from your - Print or Video Ads </span>")

}
//Trigger Menu
function initTriggerMenu() {
	var header = $('.j-header'),
		navTrigger = $('.j-nav_trigger'),
		leftMenu = $('.j-menu'),
		overlay = $('.j-overlay'),
		body = $('body'),
		close = $('.j-menu_close');


	var h = +header.outerHeight();


	// Open menu
	navTrigger.click(function (e) {
		var openMenuTl = new TimelineMax();
		e.preventDefault();
		openMenuTl
			.to(header, 0.5, {
				y: '-100%',
				ease: Power2.easeInOut
			})
			.set(leftMenu, {
				y: h
			})
			.to(leftMenu, 0.3, {
				x: '0%',
				autoAlpha: 1,
				ease: Power2.easeInOut
			}, '+=0.3')
			.to(overlay, 0.3, {
				autoAlpha: 0.5,
				display: 'block',
				ease: Power2.easeIn
			}, 0)
			.set(body, {
				className: "+=menu-open"
			})
	});


	// Close menu
	close.click(function (e) {
		e.preventDefault();
		var closeMenuTl = new TimelineMax();
		closeMenuTl
			.to(leftMenu, 0.3, {
				x: '-100%',
				autoAlpha: 0,
				ease: Power2.easeInOut
			})
			.to(overlay, 0.3, {
				autoAlpha: 0,
				display: 'none',
				ease: Power2.easeIn
			}, '-=0.1')
			.set(leftMenu, {
				y: h
			})
			.to(header, 0.3, {
				y: '0%',
				ease: Power2.easeInOut
			}, '+=0.3')
			.set(body, {
				className: "-=menu-open"
			})
	})

	$(window).resize(function () {
		var w = $(window).outerWidth();
		if (w > 1024) {
			$('body').removeClass('menu-open');
			overlay.css({
				'display': 'none',
				'opacity': '0'
			});
			header.css({
				'transform': 'translateY(0%)',
			})
			leftMenu.css({
				'transform': 'translateY(-0%)',
				'opacity': '1',
				'visibility': 'visible',
				'color': 'red'
			})
		} else {
			$('body').removeClass('menu-open');
			leftMenu.css({
				'transform': 'translateY(-100%)',
				'opacity': '0',
				'visibility': 'hidden'
			})
			overlay.css({
				'display': 'none',
				'opacity': '0'
			});
			header.css({
				'transform': 'translateY(0%)',
			})
		}
	});
}
;

// Init Hero Slider
function initHeroSlider() {
	var slider = $('.j-hero_slider');

	if (slider.length) {

		slider.css({
			opacity: 1
		}).slick({
			fade: true,
			infinite: true,
			slideToShow: 1,
			slidesToScroll: 1,
			dots: true,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: false,
			appendArrows: $('.slider-arrows'),
			lazyLoad: 'ondemand'
		});
		var currentSlide = slider.slick('slickCurrentSlide');
	}
}
;

// Hide Cover
function hideCover() {
	if ($('.j-cover').length) {
		$('body').addClass('fixed');
	}
	;

	$('.j-cover_btn').click(function (e) {
		$('.j-cover').removeClass('is-visible')
		initHeroSlider();
		var tl = new TimelineMax({
			onComplete: animateFirstSlide()
		});
		e.preventDefault();
		$('body').removeClass('fixed');
		setTimeout(function () {
			$('.j-sign').addClass('is-visible');
		}, 4000)
		tl
			.to('.j-cover', 1, {
				y: '100%',
			})
	});
}
;

// Animate First Slide
function animateFirstSlide() {
	var firstSlideTl = new TimelineMax();
	if ($('.j-hero').length) {
		firstSlideTl
			.fromTo('.j-hero_slider_title h1', 1, {
				y: -50,
				opacity: 0,
				ease: Power3.easeInOut
			}, {
				y: 0,
				opacity: 1
			}, 0)
			.fromTo('.j-hero_slider_title h2', 1, {
				y: -50,
				opacity: 0,
				ease: Power3.easeInOut
			}, {
				y: 0,
				opacity: 1
			}, 0.3)
			.from('.j-slide1_btn', 1.9, {
				scale: .001,
				y: 5,
				autoAlpha: .1,
				ease: Elastic.easeOut.config(.7, .6),
				delay: .5
			});
	}
	;

}
;

// Init Move el
function moveEl() {
	var w = $(window).outerWidth();
	if (w > 768) {
		$(window).on('mousemove', function (e) {

			// var el = $('.j-section4_el');
			var el = $('.j-move_el');
			var xPos = (e.clientX / $(window).width()) - 0.5;
			var yPos = (e.clientY / $(window).height()) - 0.5;

			el.each(function () {
				var offsetEl = $(this).data('offset');
				TweenMax.to($(this), 0.6, {
					y: offsetEl * xPos,
					x: offsetEl * yPos,
					ease: Power1.easeOut,
					transformPerspective: 900,
					transformOrigin: 'center'
				})
			});

			var arrowLeft = $('.j-parallax_arrow_left');
			var arrowRight = $('.j-parallax_arrow_right');
			var offsetArrowLeft = arrowLeft.data('offset');
			var offsetArrowRight = arrowRight.data('offset');
			TweenMax.to(arrowLeft, 0.6, {
				y: offsetArrowLeft * xPos,
				x: offsetArrowLeft * yPos,
				ease: Power1.easeOut,
				transformPerspective: 900,
				transformOrigin: 'center'
			})
			TweenMax.to(arrowRight, 0.3, {
				y: offsetArrowRight * xPos,
				x: offsetArrowRight * yPos,
				ease: Power1.easeOut,
				transformPerspective: 900,
				transformOrigin: 'center'
			});
		})
	}
}
;

// Init Sales Slider
function initSalesSlider() {
	var salesSlider = $('.j-sales_slider');

	if (salesSlider.length) {
		salesSlider.css({
			opacity: 1
		}).on({}).slick({
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 6,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			appendArrows: $('.slider-arrows'),
			lazyLoad: 'ondemand',
			responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2
				}
			},
			]
		});
	}
}
;

// Init How Slider
function initHowSlider() {
	var howSlider = $('.j-how_slider');

	if (howSlider.length) {
		howSlider.css({
			opacity: 1
		}).on({}).slick({
			dots: true,
			infinite: true,
			speed: 300,
			slidesToShow: 6,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 5000,
			appendArrows: $('.slider-arrows'),
			lazyLoad: 'ondemand',
			responsive: [{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2
				}
			},
			]
		});
	}
}
;

/* Animate Header Section */
function animateHeader() {
	var headerTl = new TimelineMax();
	headerTl
		.fromTo('.j-logo', 1, {
			x: -100,
			opacity: 0
		}, {
			x: 0,
			opacity: 1
		})
		.fromTo('.j-nav', 1, {
			x: 200
		}, {
			x: 0
		}, 0)
		.staggerFromTo('.j-nav_link', 0.5, {
			y: -30,
			opacity: 0
		}, {
			y: 0,
			opacity: 1
		}, 0.03);
}
;

// Animate Magazin (hero slider)
function animateMagazin() {
	var controller = new ScrollMagic.Controller();
	var heroMagazineTl = new TimelineMax();
	heroMagazineTl
		.to('.j-magazin', 1, {
			y: '50%',
			ease: Power0.easeNone
		})
		.to('.j-magazin_title.left', 1, {
			y: '570%',
			ease: Power0.easeNone
		}, 0)
		.to('.j-magazin_title.right', 1, {
			y: '570%',
			ease: Power0.easeNone
		}, 0)
	var magazineScene = new ScrollMagic.Scene({
		triggerElement: '.j-hero',
		triggerHook: 0,
		duration: '215%'
	})
		.setTween(heroMagazineTl)
		.addTo(controller);
}
;

//  Parallax triangle
function initParallaxTriangle() {
	var controller = new ScrollMagic.Controller();
	var heroTriangleTl = new TimelineMax();
	var triangle = $('.j-tr span');
	triangle.each(function () {
		var offset = $(this).data('offset');
		heroTriangleTl
			.to(this, 7, {
				top: offset * 4 + ('%'),
				ease: Power0.easeNone
			}, 0)

	});

	var triangleScene = new ScrollMagic.Scene({
		triggerElement: '.j-hero',
		triggerHook: 1,
		duration: '265%'
	})
		.setTween(heroTriangleTl)
		.addTo(controller);
}
;

// Sales animate
function initSalesAnimate() {
	var salesTl = new TimelineMax();
	var controller = new ScrollMagic.Controller();
	salesTl
		.staggerFromTo('.j-sales_slider .b-sales_slider_item', 0.3, {
			y: 50,
			opacity: 0
		}, {
			y: 0,
			opacity: 1
		}, 0.05)
		.from('.b-sales_footer', 0.9, {
			y: 50,
			opacity: 1
		}, ' +=0.3');
	var salesScene = new ScrollMagic.Scene({
		triggerElement: '.j-sales_slider',
		triggerHook: 0.8,
	})
		.setTween(salesTl)
		.addTo(controller);
}
;

// Help animate
function initHelpAnimate() {
	var controller = new ScrollMagic.Controller();
	$('.b-help_item').each(function () {
		var helpTl = new TimelineMax();
		var e = this;
		var img = $(this).find('img')
		var p = $(this).find('p')
		helpTl
			.staggerFromTo(img, 0.4, {
				y: 50,
				opacity: 0,
				scale: 0.001
			}, {
				y: 0,
				opacity: 1,
				scale: 1
			}, 0.1)
			.from(p, 0.4, {
				opacity: 0,
				y: 50
			}, 0);
		var helpScene = new ScrollMagic.Scene({
			triggerElement: e,
			triggerHook: 0.8,
		})
			.setTween(helpTl)
			.addTo(controller);
	})
}
;

// Work block animate
function initWorkBlockAmnimate() {

	var controller = new ScrollMagic.Controller();
	var workTl = new TimelineMax();

	workTl
		.staggerFrom('.b-works_item', 0.9, {
			cycle: {
				y: [-50, 50],

			},
			autoAlpha: 0,
			ease: Power0.easeOut
		}, 0.2)
		.staggerFrom('.b-works_item_arrow', 0.3, {

			autoAlpha: 0,
			ease: Power0.easeOut,

		}, 0.5);
	// .from('.b-works_item_arrow', 0.5, {
	//     autoAlpha: 0,
	//     // width: 0,
	//     ease: Power0.easeOut,

	// }, 1);
	var workScene = new ScrollMagic.Scene({
		triggerElement: '.b-works_item',
		triggerHook: 0.9,
		duration: '70%'
	})
		.setTween(workTl)
		.addTo(controller);
}
;

// Change Gradient
function initChangeGradient() {
	var controller = new ScrollMagic.Controller();
	$('.j-gradient').each(function () {
		var e = this;
		var gradientTl = new TimelineMax();

		gradientTl
			.to(e, 1.5, {
				css: {
					background: 'linear-gradient(0deg,#A51DBE 55%,#C72CBD 100%)'
				},
				ease: Linear.easeOut
			}, 0.2)
			.to(e, 1.5, {
				css: {
					background: 'linear-gradient(180deg,#A51DBE 55%,#C72CBD 100%)'
				},
				ease: Linear.easeOut
			}, 1)
		var salesScene = new ScrollMagic.Scene({
			triggerElement: e,
			triggerHook: 0.95,
			duration: '70%'
		})
			.setTween(gradientTl)
			// .addIndicators()
			.addTo(controller);
	})
}
;

//Init Parallax
function initParallax() {
	var parallaxTl = new TimelineMax();
	var controller = new ScrollMagic.Controller();


	parallaxTl
		.from('.b-parallax_bg', 1, {
			y: '-50%',
			ease: Power0.easeNone
		})
		.from('.j-parallax_arrow_left', 1, {
			bottom: '60%',
			ease: Power0.easeNone
		}, 0)
		.from('.j-parallax_arrow_right', 1, {
			bottom: '90%',
			ease: Power0.easeNone
		}, 0)
	var parallaxScene = new ScrollMagic.Scene({
		triggerElement: '.j-parallax',
		triggerHook: 1,
		duration: '215%'
	})
		.setTween(parallaxTl)
		.addTo(controller);

	var parallaxTextScene = new ScrollMagic.Scene({
		triggerElement: '.b-descr p',
		triggerHook: 0.95,
	})
		.setTween(TweenMax.from('.b-descr p', 0.4, {
			opacity: '0',
			y: '30',
			ease: Power0.easeNone
		}))
		.addTo(controller);
}
;

// Init Section2 Slider
function initSection2Slider() {
	var slider = $('.j-section2_slider');

	if (slider.length) {
		slider.css({
			opacity: 1
		}).on({}).slick({
			fade: true,
			infinite: true,
			slideToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 4000,
			lazyLoad: 'ondemand',
			appendArrows: $('.slider-arrows'),
		});
	}
}
;

// Init Section3 Slider
function initSection3Slider() {
	var slider = $('.j-section3_slider');

	if (slider.length) {
		slider.css({
			opacity: 1
		}).on({}).slick({
			fade: true,
			infinite: true,
			slideToShow: 1,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 4000,
			lazyLoad: 'ondemand',
			appendArrows: $('.slider-arrows'),
		});
	}
}
;


// Init Scroll


function initScroll() {

	var linkHash = window.location.hash.replace("#", "");

	if (linkHash != '') {
		if ($('.j-cover').length) {
			if ($('.j-cover').hasClass('is-visible')) {
				$('.j-cover').removeClass('is-visible');
				initHeroSlider();
				var tl = new TimelineMax({
					onComplete: animateFirstSlide()
				});
				$('body').removeClass('fixed');
				tl
					.to('.j-cover', 1, {
						y: '100%',
					})
				setTimeout(function () {
					$.scrollTo('#j-' + linkHash + '', 1500).dequeue('fx')
				}, 800)
			}
		} else {
			setTimeout(function () {
				$.scrollTo('#j-' + linkHash + '', 1500).dequeue('fx')
			}, 800)
		}
	}

};

$(document).on('click', '.j-scroll_to, .js-scroll-to', function (e) {
	var scroll = $(this).attr('data-scroll');
	var offset = -$('.j-header').outerHeight();
	var speed = parseInt($(this).attr('data-speed'), 10);
	if ($('.j-cover').hasClass('is-visible')) {
		$('.j-cover').removeClass('is-visible')
		initHeroSlider();
		var tl = new TimelineMax({
			onComplete: animateFirstSlide()
		});
		e.preventDefault();
		$('body').removeClass('fixed');
		tl
			.to('.j-cover', 1, {
				y: '100%',
			})
		if (isNaN(speed)) {
			speed = +900;
		}
		$('#j-' + scroll + '').scrollTop(0);
		setTimeout(function () {
			$.scrollTo('#j-' + scroll + '', 1500, {
				offset: offset
			}).dequeue('fx')
		}, 800)
		setTimeout(function () {
			$('.j-sign').addClass('is-visible');
		}, 4000)
	} else {
		$.scrollTo('#j-' + scroll + '', 1500, {
			offset: offset
		}).dequeue('fx');
		// return false;
	}
})
$(document).ready(function () {

	// var linkHash = window.location.hash.replace("#", "");
	// if (linkHash != '') {
	// 	console.log('#j-' + linkHash);
	// 	$.scrollTo('#j-' + linkHash + '', 1000);
	// }

})
//animate section 4
function initSection4Animate() {
	var tl = new TimelineMax();
	var controller = new ScrollMagic.Controller();
	var w = $(window).outerWidth();

	if (w > 768) {
		tl
			.from('.j-section4_container', 0.5, {
				y: '50%',
				ease: Power0.easeIn
			})
			.from('.b-section4_men', 0.1, {
				opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_shirt', 0.5, {
				y: '-40%',
				// opacity: 0,
				ease: Power0.easeIn
			}, 0.3)
			.from('.b-section4_shirt', 0.1, {
				opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_catalog', 0.5, {
				y: '-80%',
				// opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_catalog', 0.1, {
				// x: '-50%',
				opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_folder', 0.5, {
				x: '-30%',
				// opacity: 0,
				ease: Power3.easeIn
			}, 0)
			.from('.b-section4_folder', 0.2, {

				// y: '-50%',
				opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_engine', 0.5, {
				x: '30%',
				// opacity: 0,
				ease: Power3.easeIn
			}, 0)
			.from('.b-section4_engine', 0.2, {
				// x: '50%',
				opacity: 0,
				ease: Power0.easeIn
			}, 0)
			.from('.b-section4_btn', 0.7, {
				y: '-50%',
				ease: Bounce.easeOut
			}, 0)
			.from('.b-section4_btn', 0.1, {

				opacity: 0,
				ease: Power0.easeIn
			}, 0)

		var section4Scene = new ScrollMagic.Scene({
			triggerElement: '.j-section4',
			triggerHook: 0.8,
			// duration: '215%'
		})
			.setTween(TweenMax.from('.j-section4 .b-section4_title', 1, {
				opacity: '0',
				ease: Power0.easeNone
			}))
			// .addIndicators({colorStart: "#FFFF00"	})
			.addTo(controller);
		var section4ContainerScene = new ScrollMagic.Scene({
			triggerElement: '.j-section4_container',
			triggerHook: 1,
			duration: '180%'
		})
			.setTween(tl)
			.addTo(controller);
	}
}
;

//animate section 5
function initSection5Animate() {
	var tl = new TimelineMax();
	var tlBtn = new TimelineMax();
	var controller = new ScrollMagic.Controller();

	tl
		.from('.j-section5 .b-section5_title', 0.5, {
			opacity: '0',
			y: '50%',
			ease: Power0.easeNone
		})
		.from('.j-section5_bcg', 0.5, {
			y: '50%',
			opacity: 0,
			ease: Power0.easeIn
		}, 0.2)
	tlBtn
		.from('.j-section5_woman', 0.5, {
			x: '50%',
			autoAlpha: 0,
			ease: Power0.easeIn
		}, 0.5)
		.from('.j-section5_btn', 1.9, {
			scale: .001,
			y: 5,
			autoAlpha: .1,
			ease: Elastic.easeOut.config(.7, .6),
			delay: .5
		}, 0.6)

	var section5Scene = new ScrollMagic.Scene({
		triggerElement: '.j-section5',
		triggerHook: 0.8,
	})
		.setTween(tl)
		// .addIndicators()
		.addTo(controller);

	var section5WomanScene = new ScrollMagic.Scene({
		triggerElement: '.j-section5_woman',
		triggerHook: 0.7,
	})
		.setTween(tlBtn)
		// .addIndicators()
		.addTo(controller);
}
;

//animate section 6
function initSection6Animate() {
	var tl = new TimelineMax();
	var tlBtn = new TimelineMax();
	var controller = new ScrollMagic.Controller();

	var section6Scene = new ScrollMagic.Scene({
		triggerElement: '.j-section6 .b-section6_title',
		triggerHook: 0.95,
	})
		.setTween(TweenMax.from('.j-section6 .b-section6_title', 0.5, {
			opacity: '0',
			y: '50%',
			ease: Power0.easeNone
		}))
		.addTo(controller);

	var section6BtnScene = new ScrollMagic.Scene({
		triggerElement: '.j-section6',
		triggerHook: 0.4,
	})
		.setTween(TweenMax.from('.j-section6_btn', 1.9, {
			scale: .001,
			y: 5,
			autoAlpha: .1,
			ease: Elastic.easeOut.config(.7, .6),
			delay: .5
		}))
		.addTo(controller);
}
;

//animate started
function initStartedAnimate() {
	var tl = new TimelineMax();
	var controller = new ScrollMagic.Controller();

	$('.j-started_title').html($('.j-started_title').text().replace(/(\S+)/g, "<span class='letter'>$1</span>"));
	tl
		.staggerFrom('.j-started_title .letter', 0.3, {
			// cycle: {
			//     y: [-30, 30],

			// },
			y: 50,
			opacity: 0,
		}, 0.03)
		.fromTo('.j-started_img', 1, {
			y: 50,
			opacity: 0,
			ease: Power3.easeInOut
		}, {
			y: 0,
			opacity: 1
		}, 0)
	var startedScene = new ScrollMagic.Scene({
		triggerElement: '.j-started',
		triggerHook: 0.6,
	})
		.setTween(tl)
		// .addIndicators()
		.addTo(controller);
}
;

// function scrollFunction() {
// 	var h = $(window).outerHeight();
// 	if (document.body.scrollTop > h || document.documentElement.scrollTop > h) {
// 		$(".g-gotop").addClass('is-visible')

// 	} else {
// 		$(".g-gotop").removeClass('is-visible')
// 	}
// }
// ;

function initStickySidebar() {
	var w = $(window).outerWidth();
	if (w > 768) {
		if ($('.j-sidebar').length) {
			$('.j-sidebar').stickySidebar({
				topSpacing: 90,
				bottomSpacing: 0,
				containerSelector: false,
				innerWrapperSelector: '.j-sidebar_inner',
				resizeSensor: true,
				minWidth: 0
			});
		}
		;
	}


}

//Init Parallax
function initParallaxHeader() {
	var parallaxTl = new TimelineMax();
	var controller = new ScrollMagic.Controller();


	parallaxTl
		.from('.j-parallax_header_bg', 1, {
			y: '-90%',
			ease: Power0.easeNone
		})
		.to('.j-parallax_content_wrap', 1, {
			y: '-20%',
			ease: Power0.easeNone
		})
	// .to('.j-parallax_content', 1, {
	//     y: '-50%',
	//     ease: Power0.easeNone
	// })


	var parallaxScene = new ScrollMagic.Scene({
		triggerElement: '.j-parallax_header',
		triggerHook: 1,
		duration: '215%'
	})
		.setTween(parallaxTl)
		.addTo(controller);
}
;
$('.b-contact_form_input').focus(function () {
	$('.b-contact_form_group').removeClass('is-focused');
	$(this).closest('.b-contact_form_group').addClass('is-focused');
})
$('.b-contact_form_input').blur(function () {
	$(this).closest('.b-contact_form_group').removeClass('is-focused');
	if ($(this).val()) {
		$(this).closest('.b-contact_form_group').addClass('is-filled');
	} else {
		$(this).closest('.b-contact_form_group').removeClass('is-filled');
	}
})

function showBeta() {

	if ($('.j-beta').length) {
		var h = $(window).outerHeight();
		if ($(window).scrollTop() > h) {
			$('.j-beta').fadeIn()


		} else {
			$('.j-beta').fadeOut()
		}
	}
	;
}

function pricing() {
	$(document).on('click', '.js-show-modal', function (event) {
		event.preventDefault();
		var coupon = $(this).data('coupon');
		$('#couponrequestform-coupon').val(coupon);
		$('#pricing-modal-form').iziModal('open');
	});
	$('#pricing-modal-form').iziModal();

	$('#coupon-request-form').on('beforeSubmit', function (event, jqXHR, settings) {
		var form = $(this);
		if (form.find('.has-error').length) {
			return false;
		}
		$.ajax({
			url: form.attr('action'),
			type: 'post',
			data: form.serialize(),
			beforeSend: function (data) {
				form.html('<h3>Processing...</h3>');
			},
			success: function (data) {
				$('#modal-form-data').html('<h3 class="pricing-price__title">Done! Check your Inbox</h3>');
			}
		});
		return false;
	});


	//    $(document).on('click', '.js-get-premium', function (event) {
	//        event.preventDefault();
	//        var num = $('.js-price-count').text();
	//        $('.js-price-count').countTo({
	//            from: num,
	//            to: 0,
	//            decimals: 2,
	//            speed: 1000,
	//            separator: '.'
	//        });
	//        setTimeout(function () {
	//            $('#pricing-modal-access').iziModal('open');
	//        }, 2000)
	//
	//    });
	//    $('#pricing-modal-access').iziModal();
}


function storesSlider() {
	var revapi75,
		tpj = jQuery;
	if ($('#rev_slider_75_1').length) {
		if (tpj("#rev_slider_75_1").revolution == undefined) {
			revslider_showDoubleJqueryError("#rev_slider_75_1");
		} else {
			revapi75 = tpj("#rev_slider_75_1").show().revolution({
				sliderType: "standard",
				jsFileLocation: "include/rs-plugin/js/",
				sliderLayout: "fullscreen",
				dottedOverlay: "none",
				delay: 9000,
				navigation: {
					keyboardNavigation: "off",
					keyboard_direction: "horizontal",
					mouseScrollNavigation: "off",
					mouseScrollReverse: "default",
					onHoverStop: "off",
					arrows: {
						style: "new-bullet-bar",
						enable: true,
						hide_onmobile: true,
						hide_under: 778,
						hide_onleave: false,
						tmp: '<div class="tp-title-wrap">  	<div class="tp-arr-imgholder"></div>    <div class="tp-arr-img-over"></div> </div>',
						left: {
							h_align: "left",
							v_align: "center",
							h_offset: 30,
							v_offset: 0
						},
						right: {
							h_align: "right",
							v_align: "center",
							h_offset: 30,
							v_offset: 0
						}
					}
				},
				responsiveLevels: [1240, 1170, 778, 480],
				visibilityLevels: [1240, 1170, 778, 480],
				gridwidth: [1240, 1170, 778, 480],
				gridheight: [900, 768, 960, 720],
				lazyType: "none",
				parallax: {
					type: "scroll",
					origo: "slidercenter",
					speed: 1000,
					speedbg: 0,
					speedls: 1000,
					levels: [5, 10, 15, 20, 25, 30, 35, 40, 45, 46, 47, 48, 49, 50, 51, 30],
				},
				shadow: 0,
				spinner: "spinner5",
				stopLoop: "off",
				stopAfterLoops: -1,
				stopAtSlide: -1,
				shuffle: "off",
				autoHeight: "off",
				fullScreenAutoWidth: "off",
				fullScreenAlignForce: "off",
				fullScreenOffsetContainer: "",
				fullScreenOffset: "",
				hideThumbsOnMobile: "off",
				hideSliderAtLimit: 0,
				hideCaptionAtLimit: 0,
				hideAllCaptionAtLilmit: 0,
				debugMode: false,
				fallbacks: {
					simplifyAll: "off",
					nextSlideOnWindowFocus: "off",
					disableFocusListener: false,
				}
			});
		}
	};

	/*ready*/
};

function initGallery() {
	// init Isotope
	if ($('.gallery__items').length) {
		var $container = $('.gallery__items').isotope({
			itemSelector: '.gallery__item',
			layoutMode: 'fitRows'

		});
	}

	$('.gallery__menu-item').click(function (e) {
		e.preventDefault();
		$container.isotope('updateSortData').isotope({
			sortBy: 'random'
		});
	});
}


if ($('.home-modal').length) {
	// setTimeout(() => {
	// 	$('.pricing-modal').iziModal();
	// 	$('.pricing-modal').iziModal('open')
	// }, 3000);

};
function fadeIn(){

	if($('.animate-fade-up').length>0){
		$('.animate-fade-up').each(function(){
		var parallaxTl = new TimelineMax();
		var controller = new ScrollMagic.Controller();
					parallaxTl
			.to(this, 1, {
				y: '0%',
				autoAlpha: 1,
				ease: Power0.easeNone
			})


		 new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
		})
			.setTween(parallaxTl)
			.addTo(controller);
		})


	}
}
function supplHero(){

	if($('.suppl-hero__title').length>0){

		var parallaxTl = new TimelineMax();
		var controller = new ScrollMagic.Controller();
					parallaxTl
			.to('.suppl-hero__title', 0.6, {
				y: '0%',
				autoAlpha: 1,
				ease: Power0.easeNone
			})
			.to('.suppl-hero__img', 0.5, {
				y: '0%',
				x: '0%',
				autoAlpha: 1,
				ease: Power0.easeNone
			}, '+=0.2')


		 new ScrollMagic.Scene({
			triggerElement: this,
			triggerHook: 1,
		})
			.setTween(parallaxTl)
			.addTo(controller);



	}
}


$(window).scroll(function () {
	showBeta();
});

$(window).on('load', function () {
	handlePreloader();
	initTriggerMenu();
	initGallery();
});

$(document).ready(function () {
	supplHero();
	fadeIn();
	animateHeader();
	initSalesSlider();
	initSection2Slider();
	initSection3Slider();
	// initFooterFixed();
	initScroll();
	initChangeGradient();
	initParallax();
	animateMagazin();
	initParallaxTriangle();
	initSalesAnimate();
	initHelpAnimate();
	initWorkBlockAmnimate();
	hideCover();
	initSection4Animate();
	initSection5Animate();
	initSection6Animate();
	initStartedAnimate();
	moveEl();
	initHowSlider();
	// scrollFunction();
	initStickySidebar();
	initParallaxHeader();
	showBeta();
	pricing();
	storesSlider()
});